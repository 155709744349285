'use strict';

var base = require('./base');

/**
 * Enable/disable UI elements
 * @param {boolean} enableOrDisable - true or false
 */
function updateAddToCartEnableDisableOtherElements(enableOrDisable) {
    $('button.add-to-cart-global').attr('disabled', enableOrDisable);
}

module.exports = {
    methods: { updateAddToCartEnableDisableOtherElements },

    // Not needed, since it is already exported in cart.js
    // availability: base.availability,

    addToCart: base.addToCart,

    updateAttributesAndDetails() {
        $('body').on('product:statusUpdate', function (e, data) {
            var $productContainer = $(`.product-detail[data-pid="${data.id}"]`);

            $productContainer.find('.description-and-detail .product-attributes').empty().html(data.attributesHtml);

            if (data.shortDescription) {
                $productContainer.find('.description-and-detail .description').removeClass('hidden-xl-down');
                $productContainer
                    .find('.description-and-detail .description .content')
                    .empty()
                    .html(data.shortDescription);
            } else {
                $productContainer.find('.description-and-detail .description').addClass('hidden-xl-down');
            }

            if (data.longDescription) {
                $productContainer.find('.description-and-detail .details').removeClass('hidden-xl-down');
                $productContainer.find('.description-and-detail .details .content').empty().html(data.longDescription);
            } else {
                $productContainer.find('.description-and-detail .details').addClass('hidden-xl-down');
            }
        });
    },

    showSpinner() {
        $('body').on('product:beforeAddToCart product:beforeAttributeSelect', function () {
            $.spinner().start();
        });
    },
    updateAttribute() {
        $('body').on('product:afterAttributeSelect', function (e, response) {
            if ($('.product-detail>.bundle-items').length) {
                response.container.data('pid', response.data.product.id);
                response.container.find('.product-id').text(response.data.product.id);
            } else if ($('.product-set-detail').eq(0)) {
                response.container.data('pid', response.data.product.id);
                response.container.find('.product-id').text(response.data.product.id);
            } else {
                $('.product-id').text(response.data.product.id);
                $('.product-detail:not(".bundle-item")').data('pid', response.data.product.id);
            }
        });
    },
    afterAddToCart() {
        $('body').on('product:afterAddToCart', function (e, response) {
            var $popupDiv = $('#prescription-pop-up-data-disabled');
            if ($popupDiv.length && response.pliUUID) {
                $popupDiv.attr('id', 'prescription-pop-up-data');
            }
        });
    },

    updateAddToCart() {
        $('body').on('product:updateAddToCart', function (e, response) {
            // update local add to cart (for sets)
            $('button.add-to-cart', response.$productContainer).attr(
                'disabled',
                !response.product.readyToOrder ||
                    !response.product.available ||
                    response.invalidQuantity ||
                    response.disableCartForSubs
            );

            var enable = $('.product-availability')
                .toArray()
                .every(function (item) {
                    return $(item).data('available') && $(item).data('ready-to-order');
                });
            module.exports.methods.updateAddToCartEnableDisableOtherElements(!enable);
        });
    },
    updateAvailability() {
        $('body').on('product:updateAvailability', function (e, response) {
            $('div.availability', response.$productContainer)
                .data('ready-to-order', response.product.readyToOrder)
                .data('available', response.product.available);

            $('.availability-msg', response.$productContainer)
                .empty()
                .html(!response.product.available || !response.product.readyToOrder ? response.message : '');

            if ($('.global-availability').length) {
                var allAvailable = $('.product-availability')
                    .toArray()
                    .every(function (item) {
                        return $(item).data('available');
                    });

                var allReady = $('.product-availability')
                    .toArray()
                    .every(function (item) {
                        return $(item).data('ready-to-order');
                    });

                $('.global-availability').data('ready-to-order', allReady).data('available', allAvailable);

                var availabilityText = '';
                if (allReady || !response.product.available) {
                    availabilityText = response.message;
                } else if (!allReady) {
                    availabilityText = response.resources.info_selectforstock;
                }
                $('.global-availability .availability-msg').empty().html(availabilityText);
            }
        });
    },
    sizeChart() {
        $('.size-chart a').on('click', function (e) {
            e.preventDefault();
            var url = $(this).attr('href');
            var $prodSizeChart = $(this).closest('.size-chart').find('.size-chart-collapsible');
            if ($prodSizeChart.is(':empty')) {
                $.ajax({
                    url,
                    type: 'get',
                    dataType: 'json',
                    success(data) {
                        $prodSizeChart.append(data.content);
                    }
                });
            }
            $prodSizeChart.toggleClass('active');
        });

        var $sizeChart = $('.size-chart-collapsible');
        $('body').on('click touchstart', function (e) {
            if ($('.size-chart').has(e.target).length <= 0) {
                $sizeChart.removeClass('active');
            }
        });
    },
    copyProductLink() {
        $('body').on('click', '#fa-link', function () {
            event.preventDefault();
            var $temp = $('<input>');
            $('body').append($temp);
            $temp.val($('#shareUrl').val()).select();
            document.execCommand('copy');
            $temp.remove();
            $('.copy-link-message').attr('role', 'alert');
            $('.copy-link-message').removeClass('d-none');
            setTimeout(function () {
                $('.copy-link-message').addClass('d-none');
            }, 3000);
        });
    },
    scrollToTarget() {
        $('input[type=radio][name=purchaseType]').on('change', function () {
            $('.prices-add-to-cart-actions')[0].scrollIntoView({ behavior: 'smooth', block: 'end' });
        });
    },
    addPrescription() {
        $('body').on('click', '.prescription-complete-action', function (event) {
            var prescriptionPopUpData = $('#prescription-pop-up-data');
            var basketCount = $('.js-basket-count').text();
            if (prescriptionPopUpData.length && parseInt(basketCount, 10) > 0) {
                event.preventDefault();
                var title = prescriptionPopUpData.data('title');
                var msg = prescriptionPopUpData.data('msg');
                var btnText = prescriptionPopUpData.data('btn-text');
                var btnAction = $(this).attr('href');
                var btnType = prescriptionPopUpData.data('btn-type');
                var openInNewTab = prescriptionPopUpData.data('open-in-new-tab');
                var secondaryBtnText = prescriptionPopUpData.data('secondary-btn-text');
                var secondaryBtnAction = prescriptionPopUpData.data('secondary-btn-action');
                var secondaryBtnType = prescriptionPopUpData.data('secondary-btn-type');
                var secondaryBtnOpenInNewTab = prescriptionPopUpData.data('secondary-btn-open-in-new-tab');

                var modalHelper = require('../components/modal');
                modalHelper.renderGenericCtaModal(
                    title,
                    msg,
                    // First button controls
                    btnText,
                    btnAction,
                    btnType,
                    openInNewTab,
                    // Secondary button controls
                    secondaryBtnText,
                    secondaryBtnAction,
                    secondaryBtnType,
                    secondaryBtnOpenInNewTab
                );
            }
        });
    },

    focusChooseBonusProductModal: base.focusChooseBonusProductModal()
};
