'use_strict';

var base = require('app_custom_unified_portal/product/base');

module.exports = {
    updateAddToCartFormData() {
        $('body').on('updateAddToCartFormData', function (e, form) {
            if (
                $("input[name='purchaseType']:checked").val() === 'subscription' ||
                $('.subscription-oop-option-only').length > 0
            ) {
                form.schedule = $(this).find('.product-schedule-select').val();
            }
        });
        // Trigger quantity selector update when changing the selected purchase type or the
        // selected subscription frequency
        $("input[name='purchaseType'], .product-schedule-select").on('change', function () {
            var quantitySelector = base.getQuantitySelector();
            if (quantitySelector?.length) {
                // Pass true as parameter to hide all errors
                quantitySelector?.trigger('change', true);
            }
        });
    }
};
