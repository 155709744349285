/* eslint-disable prettier/prettier */

'use strict';

/**
 * Creates and shows a generic modal according to the passed parameters
 * @param {string} title - Modal title
 * @param {string} msg - Modal main text
 * @param {string} btnText - CTA button text
 * @param {string} btnAction - CTA button url. If not set, the button will act as a close button
 * @param {string} btnType - Class to give custom styles to the button
 * @param {boolean} openInNewTab - Open in a new tab if true
 * @param {string} secondaryBtnText - (Only if you need 2 buttons) CTA secondary button text (positioned to the right)
 * @param {string} secondaryBtnAction - (Only if you need 2 buttons) CTA secondary button url. If not set, the button
 * will act as a close button
 * @param {string} secondaryBtnType - (Only if you need 2 buttons) Class to give custom styles to the secondary button
 * @param {boolean} secondaryBtnOpenInNewTab - (Only if you need 2 buttons) Open the secondary button link in a new tab
 */
function renderGenericCtaModal(
    title,
    msg,
    btnText,
    btnAction,
    btnType,
    openInNewTab,
    secondaryBtnText,
    secondaryBtnAction,
    secondaryBtnType,
    secondaryBtnOpenInNewTab
) {
    // Remove previous modal if exists
    $('#ctaModal').remove();
    var modalHtml =
        '<div class="modal fade" id="ctaModal" tabindex="-1" role="dialog" ' +
        'aria-labelledby="ctaModal">' +
        '<div class="modal-dialog" role="document">' +
        '<div class="modal-content">' +
        '<div class="modal-header">' +
        `<h2 class="modal-title" id="ctaModalTitle">${title || ''}</h2>` +
        '<button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span></button></div>' +
        `<div class="modal-body">${msg || ''}</div>` +
        '<div class="modal-footer justify-content-around" style="gap: 8px;">' +
        `<div ${!secondaryBtnText ? 'class="col-12"' : ''}>` +
        `${
            btnAction
                ? `<a class="btn btn-block ${btnType || 'btn-primary'} cta-modal-action" ` +
                  `href="${btnAction || '#'}" ${openInNewTab ? 'target="_blank"' : ''}>${btnText || ''}</a>`
                : `<button class="btn btn-block ${btnType || 'btn-primary'} ` +
                  `cta-modal-action" data-dismiss="modal">${btnText || ''}</button>`
        }</div>${
            secondaryBtnText
                ? `<div>${
                    secondaryBtnAction
                        ? `<a class="btn btn-block ${secondaryBtnType || 'btn-primary'} cta-modal-action"` +
                          `href="${secondaryBtnAction || '#'}" ${secondaryBtnOpenInNewTab ? 'target="_blank"' : ''}` +
                          `>${secondaryBtnText || ''}</a>`
                        : '<button class="btn btn-block modal-secondary-button ' +
                          `${secondaryBtnType || 'btn-primary'} cta-modal-action" ` +
                          `data-dismiss="modal">${secondaryBtnText || ''}</button>`
                }</div>`
                : ''
        }` +
        '</div></div></div></div>';
    $('body').append(modalHtml);
    // Initialize the modal
    $('body > .modal-backdrop').remove();
    $('body').removeClass('modal-open');
    $('#ctaModal').modal('show');
}

module.exports = {
    renderGenericCtaModal
};
